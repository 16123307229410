import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';


const BASE_URL_WEBAPI = environment.milanaAuthorizeApiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }
  // Method to call the API and expect a string response
  getAuthData(): Observable<string> {
    return this.http.get(BASE_URL_WEBAPI + '/Authorize', { responseType: 'text' });
  }
}