import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = sessionStorage.getItem('token');
    const authorizationToken = sessionStorage.getItem('authToken');
      const authReq = req.clone({
        headers: req.headers
        .set('Authorization', 'Bearer ' + authToken)
        .set('x-auth', 'Bearer ' + authorizationToken)
      });
      return next.handle(authReq);
    }    
}