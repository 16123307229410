import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';

export const navigation: FuseNavigation[] = [
    {
        id: 'milana-applications',
        title: '',
        translate: 'NAV.EDOCKETS',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                code: 'eDockets',
                title: 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard',
                hidden:true
            },
            {
                id: 'case-management',
                code: 'eDockets',
                title: 'Milana ®',
                translate: 'NAV.CASE-MANAGEMENT.TITLE',
                type: 'item',
                icon: 'compass_calibration',
                url: '/case-management/cm/home',
                hidden:true
            },
            {
                id: 'autodocket',
                code: 'eDockets',
                title: 'Autodocket Pro ®',
                translate: 'NAV.AUTODOCKET.TITLE',
                type: 'item',
                icon: 'flash_auto',
                url: '/autodocket',
                hidden:true
            },
            {
                id: 'cr-management',
                code: 'eDockets',
                title: 'Court Rules',
                type: 'item',
                icon: 'gavel',
                url: '/cr-management',
                hidden:true
            },
            {
                id: 'Administrators',
                code: 'eDockets',
                title: 'Admin Tools',
                translate: 'NAV.ADMIN-MANAGEMENT.TITLE',
                type: 'item',
                icon: 'admin_panel_settings',
                url: '/admin-management/admin',
                hidden:true
            },
            {
                id: 'milanacalendar',
                code: 'eDockets',
                title: 'Milana Calendar',
                translate: 'NAV.CALENDAR.TITLE',
                type: 'item',
                icon: 'today',
                url: '/milana/calendar',
                hidden:true
            },
            {
                id: 'milanaquickdates',
                code: 'eDockets',
                title: 'Quick Dates',
                translate: 'NAV.QUICKDATES.TITLE',
                type: 'item',
                icon: 'access_time',
                url: '/milana/quickdates',
                hidden:true
            }
        ]
    },
    {
        id: 'enterprise',
        title: 'Enterprise Suite',
        translate: 'NAV.ENTERPRISE.TITLE',
        type: 'group',
        hidden: true,
        children: [
            {
                id: 'mail',
                code: 'mail',
                title: 'Mail 365',
                translate: 'NAV.MAIL.TITLE',
                type: 'item',
                icon: 'email',
                url: '/appgateway/mail',
                /* openInNewTab : true, */
                externalUrl: true
            },
            {
                id: 'calendar',
                code: 'calendar',
                title: 'Calendar 365',
                translate: 'NAV.CALENDAR.TITLE',
                type: 'item',
                icon: 'today',
                url: '/appgateway/calendar',
                /* openInNewTab : true, */
                externalUrl: true
            }
        ]
    },
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        hidden:true,
        children: [
            {
                id: 'efiling',
                code: 'eFiling',
                title: 'E-Filing Portal ®',
                translate: 'NAV.EFILING.TITLE',
                type: 'item',
                icon: 'queue_play_next',
                openInNewTab: true,
                externalUrl: true,
                url: 'https://central.efiling.alncorp.com/'
            },
            {
                id: 'formsworkflow',
                code: 'formsworkflow',
                title: 'Forms Workflow ®',
                translate: 'NAV.FORMSWORKFLOW.TITLE',
                type: 'item',
                icon: 'find_in_page',
                openInNewTab: true,
                externalUrl: true,
                url: 'https://www.formsworkflow.com/'
            },
            {
                id: 'docketalerts',
                code: 'docketAlerts',
                title: 'Docket Alerts ®',
                translate: 'NAV.DOCKETALERTS.TITLE',
                type: 'item',
                icon: 'format_textdirection_l_to_r',
                openInNewTab: true,
                externalUrl: true,
                url: 'https://www.docketalerts.com/'
            }
        ]
    },


];

export const docketAlertNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.EDOCKETS',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                code: 'dashboard',
                title: 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type: 'item',
                icon: 'dashboard',
                url: '/docketalert/user/dashboard'
            },
            {
                id: 'cases',
                code: 'cases',
                title: 'Case Search',
                translate: 'NAV.CASESEARCH.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/cases',
            },
            {
                id: 'myDocketAlert',
                code: 'myDocketAlert',
                title: 'My Docket Alert',
                translate: 'NAV.MYDOCKETALERT.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/docket-alerts',
            },
            {
                id: 'myeDocsAlert',
                code: 'myeDocsAlert',
                title: 'My eDocs Alert',
                translate: 'NAV.MYEDOCSALERT.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/eDocs-alerts',
            },
            {
                id: 'myCases',
                code: 'myCases',
                title: 'All My Case',
                translate: 'NAV.ALLMYCASE.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/my-cases'
            },
            {
                id: 'myReports',
                code: 'myReports',
                title: 'My Reports',
                translate: 'NAV.MYREPORTS.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/user/account-activities'
            },
            {
                id: 'ordersAndOpinions',
                code: 'ordersAndOpinions',
                title: 'Orders and Opinions',
                translate: 'NAV.ORDERSOPINIONS.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/orders-opinions'
            },
            {
                id: 'partyWatch',
                code: 'partyWatch',
                title: 'Party Watch',
                translate: 'NAV.PARTYWATCH.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/party-watch'
            },
            {
                id: 'natureOfSuit',
                code: 'natureOfSuit',
                title: 'Nature of Suit Watch',
                translate: 'NAV.NATURESUITWATCH.TITLE',
                type: 'item',
                icon: 'today',
                url: '/docketalert/nature-of-suit'
            }
        ]
    },
    {
        id: 'enterprise',
        title: 'Enterprise Suite',
        translate: 'NAV.ENTERPRISE.TITLE',
        type: 'group',
        hidden: true,
        children: [
            {
                id: 'mail',
                code: 'mail',
                title: 'Mail 365',
                translate: 'NAV.MAIL.TITLE',
                type: 'item',
                icon: 'email',
                url: '/appgateway/mail',
                /* openInNewTab : true, */
                externalUrl: true
            },
            {
                id: 'calendar',
                code: 'calendar',
                title: 'Calendar 365',
                translate: 'NAV.CALENDAR.TITLE',
                type: 'item',
                icon: 'today',
                url: '/appgateway/calendar',
                /* openInNewTab : true, */
                externalUrl: true
            }
        ]
    },
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'eDockets',
                code: 'eDockets',
                title: 'eDockets',
                translate: 'NAV.DASHBOARD.TITLE',
                type: 'item',
                icon: 'compass_calibration',
                url: '/'
            },
            {
                id: 'efiling',
                code: 'eFiling',
                title: 'E-Filing Portal ®',
                translate: 'NAV.EFILING.TITLE',
                type: 'item',
                icon: 'queue_play_next',
                openInNewTab: true,
                externalUrl: true,
                url: 'https://central.efiling.alncorp.com/',
            },
            {
                id: 'smartdockets',
                code: 'smartDockets',
                title: 'Smart Dockets ®',
                translate: 'NAV.SMARTDOCKETS.TITLE',
                type: 'item',
                icon: 'fiber_smart_record',
                openInNewTab: true,
                externalUrl: true,
                url: 'https://smartdockets.alncorp.com/appgateway/home'
            },
            {
                id: 'formsworkflow',
                code: 'formsworkflow',
                title: 'Forms Workflow ®',
                translate: 'NAV.FORMSWORKFLOW.TITLE',
                type: 'item',
                icon: 'find_in_page',
                openInNewTab: true,
                externalUrl: true,
                url: 'https://www.formsworkflow.com/'
            }
        ]
    }
];
